import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Slider from '@mui/material/Slider'

export default function SliderQuestion(props) {

    const steps = props.steps

    const marksArray = Array.isArray(props.marks)
    ? props.marks
    : Object.keys(props.marks).map((key) => ({ value: Number(key), label: props.marks[key] }));

    return (
        <Grid container alignItems='center' justifyContent='center' spacing={1} sx={{ textAlign: 'center', width: '100%' }}>
            <Grid xs={12}>
                <h2>{props.title}</h2>
                {props.subtitle ? (
                    <p className='question-subtext'>{props.subtitle}</p>
                ) : (
                    <p className='question-subtext hide'>Hide</p>
                )}
            </Grid>
            <Grid xs={12}>
                <Slider
                    value={typeof props.answer === 'number' ? props.answer : props.min}
                    onChange={(event, value) => props.updateAnswer(value)}
                    aria-labelledby="slider"
                    valueLabelDisplay="auto"
                    min={props.min}
                    max={props.max}
                    step={props.step}
                    marks={marksArray}
                    sx={{
                        color: '#038080',
                        '& .MuiSlider-track': { border: 'none', height: 10 },
                        '& .MuiSlider-rail': { opacity: 0.5, backgroundColor: '#bfbfbf', height: 10 },
                        '& .MuiSlider-thumb': { height: 24, width: 24 }
                    }}
                />
            </Grid>
        </Grid>
    )
}